<template>
  <div class="p-p-3">
    <i class="pi pi-circle-on hf1 orange p-d-inline"></i>
    <div class="hf1 orange p-d-inline p-ml-3">About Us</div>
    <div class="card2 white p-shadow-4 p-mt-3 p-p-3" style="color: white">
      
      <p class="ql-align-justify">
        <span style="color: rgb(0, 0, 0)"
          >Most people play games for recreation. However, there are some people
          who have serious gambling problems that negatively impact the lives
          and lives of their friends and family. Someone who plays excessively
          or obsessively can ignore spouses, children, and friends. They may
          start performing poorly at work or school, getting into debt or
          playing with borrowed money. The problem of gambling is not limited to
          certain social status, ethnic group, gender or age.</span
        >
      </p>
      <p class="ql-align-justify"><br /></p>
      <p class="ql-align-justify">
        <strong style="color: rgb(0, 0, 0)"><em>Bogorpools.com</em></strong
        ><span style="color: rgb(0, 0, 0)">
          realizes that while most people gamble for entertainment, a small
          number of people can become obsessed with playing games by chance.
          Although research shows that only a small percentage of the adult
          population is facing the problem of compulsive gambling, we take the
          matter seriously and therefore apply a number of steps to address this
          problem:</span
        >
      </p>
      <p class="ql-align-justify"><br /></p>
      <ol>
        <li class="ql-align-justify">
          <span style="color: rgb(0, 0, 0)">The </span
          ><em style="color: rgb(0, 0, 0)">Rusiapools.com</em
          ><span style="color: rgb(0, 0, 0)"
            >&nbsp;training program combines methods and techniques that help
            our employees recognize and take appropriate action when they
            identify compulsive or underage gambling.</span
          >
        </li>
        <li class="ql-align-justify">
          <span style="color: rgb(0, 0, 0)"
            >We have implemented an easy-to-use self-exclusion program for those
            who need our help. Upon your request we will cancel your membership
            and will prevent you from entering our casino or our poker
            room.</span
          >
        </li>
        <li class="ql-align-justify">
          <span style="color: rgb(0, 0, 0)"
            >We let you set the maximum amount of deposit allowed.</span
          >
        </li>
        <li class="ql-align-justify">
          <span style="color: rgb(0, 0, 0)"
            >Upon your request we will remove your name from our email
            list.</span
          >
        </li>
      </ol>
      <p class="ql-align-justify"><br /></p>
      <p class="ql-align-justify">
        <strong style="color: rgb(255, 0, 0)"><em>Remember!</em></strong>
      </p>
      <ul>
        <li class="ql-align-justify">
          <span style="color: rgb(0, 0, 0)"
            >Gambling is a form of entertainment. This is not a way to get rich
            quickly and pay off debts.</span
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >is a coincidence game. There is no formula that guarantees
            victory.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Make sure that the decision to gamble is your choice.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)">Never try to pursue your loss.</em>
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Set a money limit according to how much you are able to play and
            regularly check the amount you spend at the Cashier.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Like many things in life, fun activities in moderation can be an
            excessive disaster.</em
          >
        </li>
      </ul>
      <p class="ql-align-justify"><br /></p>
      <p class="ql-align-justify">
        <strong style="color: rgb(0, 0, 0)">Tips for gambling safely</strong>
      </p>
      <p class="ql-align-justify">
        <span style="color: rgb(0, 0, 0)"
          >Gambling recreation is fun. Obsessive gambling is not!</span
        >
      </p>
      <p class="ql-align-justify"><br /></p>
      <ul>
        <li class="ql-align-justify">
          <strong style="color: rgb(255, 0, 0)"><em>Remember!</em></strong
          ><em style="color: rgb(0, 0, 0)"
            >Gambling is a form of entertainment. This is not a way to get rich
            quickly and pay off your debts.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Gambling is a coincidence game. There is no formula that guarantees
            victory.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Make sure that the decision to gamble is your choice and you are
            not encouraged or sure to gamble.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Determine how much money you can lose before playing. When the
            number is gone-stop playing. Regularly check the amount you pay in
            Cashier.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Before you start playing, decide how much time you can spend. Stop
            playing when time is over.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Make sure you know the rules of the game you are playing.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Never pursue your loss - never return the lost money. This results
            in greater losses.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Make sure gambling does not exclude you to enjoy other activities
            in your life. The balance of gambling with other forms of
            recreation. Do not interfere with work and family.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >Never gamble when you`re emotional - it`s hard to make rational
            decisions when you`re upset or depressed.</em
          >
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)">Never borrow money to gamble.</em>
        </li>
        <li class="ql-align-justify">
          <em style="color: rgb(0, 0, 0)"
            >A recently published study estimated that 39% of compulsive
            gamblers were able to solve their own gambling problems.</em
          >
        </li>
      </ul>
      <p><br /></p>
      <p>
        <span style="color: rgb(0, 0, 0)"
          >Please play with responsibility!</span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>